<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="versions"
      class="elevation-1"
      :options.sync="pagination"
      :server-items-length="versionPagination.totalItems"
      :items-per-page="versionPagination.rowsPerPage"
    >
      <template v-slot:top>
        <v-alert dense v-model="alert" type="success">
          Changes saved successfully.
        </v-alert>
        <v-toolbar flat>
          <v-toolbar-title>Versions</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <router-link
            :to="{ name: 'CreateVersions' }"
            class="nav-link"
            v-bind:class="{ active: $route.name === 'CreateVersions' }"
          >
            <v-btn color="primary" dark class="mb-2">
              New Version
              <v-icon right dark> mdi-plus </v-icon>
            </v-btn>
          </router-link>
          <ConfirmDelete
            v-if="deleteItem"
            :item="deleteItem"
            :delete-action="deleteAction"
            @close="deleteItem = null"
          />
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="3">
              <v-select
                v-model="platformId"
                clearable
                :items="platforms"
                item-text="name"
                item-value="id"
                filled
                label="Platform"
                v-on:change="
                  fetchVersions(
                    versionPagination.page,
                    versionPagination.rowsPerPage,
                    platformId
                  )
                "
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-btn
          v-if="item.status === 0"
          @click="makeVersionActive(item)"
          class="btn-status"
        >
          Inactive
        </v-btn>
        <div v-else>Active</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link :to="{ name: 'UpdateVersions', params: { id: item.id } }">
          <v-icon small class="mr-2"> mdi-pencil </v-icon>
        </router-link>
        <v-icon small @click="deleteItem = item"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data v-if="versionLoading">
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  GET_VERSIONS,
  CREATE_VERSION,
  UPDATE_VERSION,
  DELETE_VERSION,
  RESET_VERSION_ERRORS,
  MAKE_VERSION_ACTIVE,
} from "@/store/versions.module";
import ConfirmDelete from "@/components/modals/ConfirmDelete";
import { SET_VERSION_LOADING } from "../../../store/versions.module";
import { GET_PLATFORMS } from "@/store/options.module";

export default {
  name: "List",
  components: { ConfirmDelete },
  data: () => ({
    alert: false,
    dialog: false,
    deleteAction: DELETE_VERSION,
    deleteItem: null,
    headers: [
      {
        text: "Version",
        value: "version",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Platform",
        value: "platform.name",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    defaultItem: {
      id: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    showPassword: false,
    showPasswordConfirmation: false,
    platformId: null,
  }),
  computed: {
    ...mapState([
      "versions",
      "versionErrors",
      "versionPagination",
      "versionLoading",
      "platforms",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Version" : "Edit Version";
    },
    passwordMinCharactersRule() {
      return () =>
        typeof this.editedItem.password === "undefined" ||
        this.editedItem.password.length === 0 ||
        this.editedItem.password.length >= 8 ||
        "Min 8 characters";
    },
    totalItems() {
      return this.versionPagination.totalItems;
    },
    rowsPerPageItems() {
      return this.versionPagination.rowsPerPageItems;
    },
    pagination: {
      get: function () {
        return this.versionPagination;
      },
      set: function (value) {
        if (
          value.page != this.pagination.page &&
          this.pagination.rowsPerPage != value.itemsPerPage
        ) {
          this.fetchVersions(value.page, value.itemsPerPage, this.platformId);
        }
      },
    },
  },
  mounted() {
    this.fetchVersions();
    this.getPlatforms();
  },
  watch: {
    pagination: {
      handler() {
        if (this.versionPagination.total) {
          this.fetchVersions(this.versionPagination.page);
        }
      },
      update() {
        this.fetchVersions();
      },
      deep: true,
    },
    alert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  created() {},
  methods: {
    makeVersionActive(version) {
      this.$store.dispatch(MAKE_VERSION_ACTIVE, version.id).then(() => {
        version.status = 1;
      });
    },
    fetchVersions(page = 1, itemsPerPage = 15, platformId = null) {
      if (!this.loadingVersions) {
        this.$store.commit(SET_VERSION_LOADING, true);

        this.$store
          .dispatch(GET_VERSIONS, { page, itemsPerPage, platformId })
          .then(() => {
            this.$nextTick(() => {
              this.$store.commit(SET_VERSION_LOADING, false);
            });
          });
      }
    },
    editItem(item) {
      this.editedIndex = this.versions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$store.commit(RESET_VERSION_ERRORS);
    },

    displayAlert() {
      this.alert = true;
    },

    save() {
      let action = CREATE_VERSION;

      if (this.editedIndex > -1) {
        action = UPDATE_VERSION;
      }

      this.$store
        .dispatch(action, this.editedItem)
        .then(this.close)
        .then(this.displayAlert);
    },
    getPlatforms() {
      this.$store.dispatch(GET_PLATFORMS);
    },
  },
};
</script>

<style scoped>
.btn-status {
  background-color: #e0e0e0;
  font-size: 12px;
  height: 24px;
  padding: 0 10px;
}
</style>
